import React from 'react';
import './App.scss';
import { TopNavigation, Grid, Box, Container, SpaceBetween, Button, Link, Cards, Icon, IconProps } from '@cloudscape-design/components';

function App() {
  return (
    <div className="App">
      <div className='header'>
        <TopNavigation
        identity={{
          href: "#",
          title: "Social Invest",
          logo: {
            src: "/logo.png",
            alt: "Service"
          }
        }}
        utilities={[]} 
      />
      </div>
      <div className='body'>
        <div className="full-header">
          <div
            className="header-background-image"
            style={{ backgroundImage: 'url(/banner.jpg)' }}
          />
          <div className="inner-header">
            <Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }]}>
              <Container>
                <Box padding="s">
                  <Box fontSize="display-l" fontWeight="bold" variant="h1" padding="n">
                    Social Invest
                  </Box>
                  <Box fontSize="display-l" fontWeight="light">
                    Invest with your community
                  </Box>
                  <Box variant="p" color="text-body-secondary" margin={{ top: 'xs', bottom: 'l' }}>
                    Join a movement of investors who share and grow their wealth together. Get started with social invest.
                  </Box>
                  <Button variant="primary" href='#waitlist'>Join the waitlist</Button>
                </Box>
              </Container>
            </Grid>
          </div>
        </div>
        <div className='content'>
          <Grid gridDefinition={
            [{colspan: 10, push: 1}]
          }
          >
            <Box padding="xl">
              <h1>
                Why Social Invest?
              </h1>
              <p>
                Social Invest is an investing platform that helps you support the issues you care about. We offer a range of investment options, expert guidance, and a community of like -minded investors investing.
              </p>
              <Cards 
                ariaLabels={{
                  itemSelectionLabel: (e, t) => `Feature: ${t.name}`,
                  selectionGroupLabel: "Features"
                }}
                cardDefinition={{
                  header: item => (
                    <div className='feature-card'>
                      <Icon size="medium" name={item.type}></Icon>
                    </div>
                  ),
                  sections: [
                    {
                      id: "description",
                      content: item => (
                        <div className='feature-card'>
                          <strong>{item.name}</strong>
                          <p>{item.description}</p>
                        </div>
                      )
                    }
                  ]
                }}
                cardsPerRow={[
                  { cards: 1 },
                  { minWidth: 500, cards: 2 }
                ]}
                items={[
                  {
                    name: "Invest in what you believe in",
                    description: "Find companies that align with your values, like renewable energy or social justice",
                    type: ("suggestions" as IconProps.Name),
                  },
                  {
                    name: "Grow your money with us",
                    description: "Choose from investment options like stocks, ETFs, and crypto, with more coming soon",
                    type: ("gen-ai" as IconProps.Name),
                  },
                  {
                    name: "Join a community of change makers",
                    description: "Connect with other investors to share ideas, learn from experts, and drive change together",
                    type: ("group" as IconProps.Name),
                  },
                  {
                    name: "Build your financial knowledge",
                    description: "Access resources for every level of investor, from our educational hub to personal portfolio guidance",
                    type: ("status-info" as IconProps.Name),
                  }
                ]}
              />
            </Box>
          </Grid>
        </div>
        <div id='waitlist'>
          <iframe
              className='waitlist-iframe'
              allow="clipboard-write"
              id="waitlist_iframe"
              src="https://getwaitlist.com/waitlist/17202"
              title='waitlist'
          >
          </iframe>
        </div>
        <div className='footer'>
          <Box display="inline-block" padding="m">
            <SpaceBetween direction="horizontal" size="l" alignItems='center'>
              <Link href='mailto:contactus@socialinvest.app' variant="secondary">Contact Us</Link>
              <Link href='#legal' variant="secondary">Legal</Link>
              <Link href='#privacy' variant="secondary">Privacy</Link>
              <Link href='#security' variant="secondary">Security</Link>
            </SpaceBetween>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default App;
